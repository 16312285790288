import React from 'react';
import {
  Box,
  Button,
  colors,
  spacing,
  Flex,
  Grid,
  P,
  Popover,
  useI18nContext,
  Typography,
} from '@procore/core-react';
import { Check } from '@procore/core-icons';
import PricingSection from './components/Sections/Pricing';

import { appInstallPost } from '@/react/api';
import { MediaContainer, SidebarContainer } from '@/react/styles/v2';
import * as Styles from '@/react/styles';
import { AppHeader, InstallRequestButton } from '@/react/components';
import { useAppContext } from '@/react/context/AppContext';

import {
  OverviewSection,
  MediaSection,
  SideBar,
  SupportSection,
  FunctionalitySection,
  SecuritySection,
} from './components/Sections';
import { sendAnalytics } from './utils';

const AppDetails = () => {
  const i18n = useI18nContext();
  const {
    appDetails: {
      app,
      metricsData,
      installationRequest,
      companyInstallationRequestsEnabled,
      companyName,
      user,
      wistiaId,
    },
  } = useAppContext();

  const {
    installed,
    developer_app_id,
    direct_installable,
    install_path,
    screenshots,
  } = app;

  const colWidthsContent = { desktopSm: 8, tabletLg: 12 };
  const colWidthSidebar = { desktopSm: 4, tabletLg: 12 };

  const getActionButton = () => {
    if (installed) {
      return (
        <Box display="flex" gap="sm" padding="md 0">
          <Check />
          <Typography intent="h3">{i18n.t('labels.installed')}</Typography>
        </Box>
      );
    }

    if (direct_installable) {
      if (!user.isSignedIn) {
        return (
          <form method="post" action="/auth/procore">
            <input
              type="hidden"
              name="authenticity_token"
              value={document.querySelector('meta[name="csrf-token"]').content}
            />
            <Button type="submit" data-qa="install-signin-button" size="lg">
              {i18n.t('appDetails.loginToInstall')}
            </Button>
          </form>
        );
      }

      if (!user.isCompanyAdmin) {
        if (companyInstallationRequestsEnabled) {
          return (
            <InstallRequestButton
              installRequestsPath={installationRequest.apiPath}
              developerAppId={developer_app_id}
              requested={installationRequest.present}
              sendAnalytics={sendAnalytics}
            />
          );
        }

        const content = (
          <Popover.Content>
            <Box padding="md">
              <P>{i18n.t('appDetails.mustBeAdmin')}</P>
            </Box>
          </Popover.Content>
        );

        return (
          <Popover overlay={content} trigger="hover" key="example-2">
            {() => (
              <Box>
                <Button disabled data-qa="install-signin-button" size="lg">
                  {i18n.t('appDetails.installApp')}
                </Button>
              </Box>
            )}
          </Popover>
        );
      }

      if (install_path) {
        return (
          <Button
            id="app-install-button"
            onClick={() => {
              sendAnalytics(
                'click',
                'Button',
                'Install App',
                'install_button.clicked'
              );
              appInstallPost(app);
              window.location = install_path;
            }}
            size="lg"
          >
            {i18n.t('appDetails.installApp')}
          </Button>
        );
      }
    }
    return null;
  };

  return (
    <Flex justifyContent="center">
      <Flex direction="column" flex="1">
        <AppHeader
          app={app}
          companyName={companyName}
          getActionButton={getActionButton}
          metricsData={metricsData}
          sendAnalytics={sendAnalytics}
          user={user}
        />
        <Styles.Container style={{ maxWidth: 'calc(100vw - 15px)' }}>
          <Flex justifyContent="center">
            <MediaContainer>
              <Grid
                colStackCap="tabletMd"
                gutterX={{ tabletLg: 'xxl' }}
                gutterY={{ tabletSm: 'xl' }}
              >
                <Grid.Row>
                  <Grid.Col colWidth={colWidthsContent}>
                    <Flex justifyContent="center">
                      <MediaSection
                        screenshots={screenshots}
                        wistiaId={wistiaId}
                      />
                    </Flex>
                  </Grid.Col>
                  <Grid.Col colWidth={colWidthSidebar}>
                    <SidebarContainer>
                      <SideBar />
                    </SidebarContainer>
                  </Grid.Col>
                </Grid.Row>
              </Grid>
            </MediaContainer>
          </Flex>

          <Box style={{ width: '100%', backgroundColor: colors.white }}>
            <Styles.ContentContainer>
              <Flex direction="column">
                <OverviewSection description={app.description} />
                <hr
                  style={{
                    border: 0,
                    'border-top': `1px solid ${colors.gray70}`,
                    width: '100%',
                  }}
                />
                <FunctionalitySection
                  howItWorks={app.how_it_works}
                  features={app.feature_bullets}
                  requirements={app.requirements}
                  tools={app.product_tools}
                />
              </Flex>
            </Styles.ContentContainer>
          </Box>

          <Box
            style={{
              width: '100%',
              'background-color': `${colors.gray96}`,
              'padding-top': `${spacing.xl}px`,
              'padding-bottom': `${spacing.xl}px`,
            }}
          >
            <Flex
              justifyContent="center"
              alignItems="flex-start"
              style={{ maxWidth: '780px', margin: 'auto', background: 'white' }}
            >
              <PricingSection
                pricingDescription={app.pricing_description}
                subscriptionRequired={app.subscription_required}
                connectorRequired={app.connector_required}
                hasTrials={app.has_trials}
                pricingUrl={app.pricing_url}
                email={app.contact_us_email}
                builtBy={app.built_by}
                pricing={app.pricing}
              />
            </Flex>
          </Box>
          <Box style={{ width: '100%', backgroundColor: colors.white }}>
            <Styles.ContentContainer>
              <SecuritySection
                app={app}
                questionnaire={app.security_questionnaire_document}
              />
            </Styles.ContentContainer>
          </Box>
          <SupportSection
            helpfulLinks={app.helpful_links}
            contactUsEmail={app.contact_us_email}
            website={app.website}
            name={app.name}
          />
        </Styles.Container>
      </Flex>
    </Flex>
  );
};

export default AppDetails;

import React from 'react';

const MobileLogo = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.565 0H3.26178C1.37195 0 0 1.37195 0 3.26178V12.7382C0 14.6281 1.37195 16 3.26178 16H13.565C15.4549 16 16.8268 14.6281 16.8268 12.7382V10.1488H12.9926L11.3208 13.029H5.49688L2.58944 8.00454L5.49688 2.98012H11.3208L12.9926 5.86031H16.8268V3.26178C16.8268 1.37195 15.4549 0 13.565 0Z"
        fill="black"
      />
      <path
        d="M6.83245 5.26971L5.26062 7.99544L6.83245 10.7212H9.99429L11.5661 7.99544L9.99429 5.26971H6.83245Z"
        fill="#FF5100"
      />
    </svg>
  );
};

export default MobileLogo;

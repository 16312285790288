import React from 'react';
import PT from 'prop-types';
import {
  LinkButton,
  Typography,
  Box,
  Avatar,
  useI18nContext,
  colors,
} from '@procore/core-react';
import { Building, Import, Person } from '@procore/core-icons';
import styled from 'styled-components';

const FullButton = styled(LinkButton)`
  align-items: center;
  justify-content: flex-start;
`;

const UserSection = ({
  currentUser,
  currentCompany,
  changeCompanyPath,
  signOutPath,
}) => {
  const i18n = useI18nContext();
  return (
    <Box
      display="flex-column"
      gap="lg"
      justifyContent="center"
      alignItems="center"
    >
      <Box display="flex-column" gap="sm" alignItems="center">
        <Avatar
          aria-label={i18n.t('ariaLabels.account')}
          size="lg"
          style={{ width: '60px', height: '60px' }}
        >
          <Avatar.Icon icon={<Person size="xl" />} />
        </Avatar>
        <Typography intent="body">{currentUser.email}</Typography>
      </Box>

      <Typography
        intent="h3"
        weight="semibold"
        aria-label={currentCompany.name}
      >
        {currentCompany.name}
      </Typography>

      <Box display="flex-column" gap="sm" style={{ width: '100%' }}>
        <FullButton
          href={changeCompanyPath}
          target="_blank"
          variant="secondary"
          block
        >
          <Box
            display="flex"
            gap="sm"
            alignItems="center"
            justifyContent="flex-start"
            padding="xs md"
          >
            <Building />
            <Typography intent="label">
              {i18n.t('navbar.companySwitch')}
            </Typography>
          </Box>
        </FullButton>

        <FullButton href={signOutPath} target="_self" variant="secondary" block>
          <Box
            display="flex"
            gap="sm"
            alignItems="center"
            justifyContent="flex-start"
            padding="xs md"
          >
            <Import color={colors.red50} />
            <Typography intent="label" color="red50">
              {i18n.t('navbar.logout')}
            </Typography>
          </Box>
        </FullButton>
      </Box>
    </Box>
  );
};

UserSection.propTypes = {
  currentUser: PT.shape({ email: PT.string }).isRequired,
  currentCompany: PT.shape({ name: PT.string }).isRequired,
  changeCompanyPath: PT.string.isRequired,
  signOutPath: PT.string.isRequired,
};

export default UserSection;

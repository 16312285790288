import React from 'react';
import {
  Box,
  Grid,
  Flex,
  Typography,
  H1,
  H2,
  H3,
  P,
  Link,
  colors,
  spacing,
  useI18nContext,
} from '@procore/core-react';
import * as Styles from '@/react/styles';
import { ArrowRight } from '@procore/core-icons';
import { useAppContext } from '@/react/context/AppContext';

const PricingSection = ({
  pricingDescription,
  subscriptionRequired,
  connectorRequired,
  hasTrials,
  pricingUrl,
  email,
  builtBy,
  pricing,
}) => {
  const i18n = useI18nContext();

  return (
    <>
    {!pricing && (
       <Flex
        direction="row"
        padding="xl"
        gap="lg"
        data-testid="pricing-not-available-section"
       >
        <img
          src="/images/privacy-icon.png"
          alt=""
          style={{ width: '96px', height: '96px' }}
        />
        <Box display="block">
            <H2>{i18n.t('appDetails.pricing.titleNoPricing')}</H2>
            <P>{i18n.t('appDetails.pricing.descriptionNoPricing')}
            <Link href={`mailto:${email}`}>
              <Typography intent="body" style={{ "color": `${colors.blue45}` }}>
                {i18n.t('appDetails.pricing.contactPartner')}
              </Typography>
            </Link>
            </P>
        </Box>
       </Flex>
    )}

    {pricing && (
      <Flex
        direction="column"
        padding="xl"
        margin="lg"
        alignContent="flex-start"
        justifyContent="flex-start"
        data-testid="pricing-section"
      >
        <Grid.Row>
          <Grid.Col>
            <H1>{i18n.t('appDetails.pricing.title')}</H1>
            <Box marginTop="sm">
              <H3>
                {i18n.t('appDetails.pricing.soldBy')}
                {builtBy}
              </H3>
              <P>{pricingDescription}</P>
              <H2>
                <Styles.OrangeLink href={pricingUrl} target="_blank">
                  {i18n.t('appDetails.pricing.website')}
                  <ArrowRight
                    style={{ paddingTop: `${spacing.sm}px`, fontWeight: 'bold' }}
                  />
                </Styles.OrangeLink>
              </H2>
            </Box>
          </Grid.Col>

          <Grid.Col colWidth={6}>
            <H2>{i18n.t('appDetails.pricing.faqs')}</H2>
            <Box
              padding="md"
              marginTop="sm"
              marginBottom="md"
              style={{ "background-color": `${colors.gray96}` }}
            >
              <Typography intent="body">
                {i18n.t('appDetails.pricing.faqsHeaderPart1')}
              </Typography>
              <Link href={`mailto:${email}`}>
                <Typography intent="body" style={{ "color": `${colors.blue45}` }}>
                  {i18n.t('appDetails.pricing.faqsHeaderPart2')}
                </Typography>
              </Link>
              <Typography intent="body">
                {i18n.t('appDetails.pricing.faqsHeaderPart3')}
              </Typography>
            </Box>

            <H3>
              {i18n.t('appDetails.pricing.subscriptionRequired.description')}
            </H3>
            {subscriptionRequired && (
              <P>{i18n.t('appDetails.pricing.subscriptionRequired.yes')}</P>
            )}
            {!subscriptionRequired && (
              <P>{i18n.t('appDetails.pricing.subscriptionRequired.no')}</P>
            )}

            <H3>
              {i18n.t(
                'appDetails.pricing.thirdPartyConnectorRequired.description'
              )}
            </H3>
            {connectorRequired && (
              <P>
                {i18n.t(
                  'appDetails.pricing.thirdPartyConnectorRequired.yes'
                )}
              </P>
            )}
            {!connectorRequired && (
              <P>
                {i18n.t('appDetails.pricing.thirdPartyConnectorRequired.no')}
              </P>
            )}

            <H3>
              {i18n.t('appDetails.pricing.freeTrialOffered.description')}
            </H3>
            {hasTrials && (
              <P>{i18n.t('appDetails.pricing.freeTrialOffered.yes')}</P>
            )}
            {!hasTrials && (
              <P>{i18n.t('appDetails.pricing.freeTrialOffered.no')}</P>
            )}
          </Grid.Col>
        </Grid.Row>
      </Flex>
      )}
    </>
  );
};

export default PricingSection;

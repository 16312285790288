import React, { useEffect, useRef } from 'react';
import PT from 'prop-types';
import styled from 'styled-components';
import {
  Button,
  Box,
  Input,
  colors,
  useI18nContext,
  Typography,
} from '@procore/core-react';
import { Search } from '@procore/core-icons';
import { useSearch } from '@/react/context/SearchContext';
import { DropdownMenu } from '@/react/styles';
import SearchResults from './SearchResults';

const SearchBox = styled(Box)`
  border-radius: 100px;
  width: 100%;
  padding: 6px 16px 6px 8px;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || colors.gray94};

  &:hover,
  &:focus-within {
    background-color: ${({ hoverColor }) => hoverColor || colors.gray90};
  }

  @media (min-width: 576px) {
    min-width: 428px;
  }
`;

const SearchInput = styled(Input)`
  display: block;
  border: none;
  padding: 0px;
  width: 100%;
  background-color: inherit;

  &:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }
`;

const SearchButton = styled(Button)`
  border-radius: 100px;

  &:hover {
    background-color: ${colors.gray85};
  }
`;

const SearchResultsMenu = styled(DropdownMenu)`
  position: absolute;
  left: 0;
  right: 0;
  max-height: 650px;
  overflow-y: auto;
`;

const SearchBar = ({ styledSearch }) => {
  const SEARCH_TERM_THRESHOLD = 2;
  const i18n = useI18nContext();
  const { searchTerm, setSearchTerm, appSearchResults, isLoading } =
    useSearch();
  const searchRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setSearchTerm('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setSearchTerm]);

  return (
    <Box style={{ position: 'relative' }} ref={searchRef}>
      <SearchBox
        backgroundColor={styledSearch?.backgroundColor}
        hoverColor={styledSearch?.hoverColor}
        display="flex"
        gap="sm"
        alignItems="center"
      >
        <SearchButton
          icon={<Search />}
          size="md"
          aria-label="Search"
          variant="tertiary"
          color={colors.black}
        />
        <SearchInput
          type="text"
          placeholder={i18n.t('labels.searchInput')}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </SearchBox>
      {searchTerm.length > SEARCH_TERM_THRESHOLD && (
        <SearchResultsMenu>
          {isLoading ? (
            <Typography intent="body" color="gray10" weight="semibold">
              {i18n.t('labels.searchLoading')}
            </Typography>
          ) : (
            <SearchResults results={appSearchResults} searchTerm={searchTerm} />
          )}
        </SearchResultsMenu>
      )}
    </Box>
  );
};

SearchBar.propTypes = {
  styledSearch: PT.shape({
    backgroundColor: PT.string,
    hoverColor: PT.string,
  }),
};

export default SearchBar;

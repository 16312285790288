import React, { useState } from 'react';
import {
  Link,
  Modal,
  useI18nContext,
  Button,
  H3,
  Flex,
  P,
} from '@procore/core-react';
import { UnderlineModalHeader, MinWidthModal, MinWidthModalBody } from '@/react/styles/v2';

export const VersionHistoryModal = ({ versionHistory }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const i18n = useI18nContext();
  return (
    <>
      <Link onClick={() => setIsModalOpen(true)}>
        {i18n.t('appDetails.sideBar.historyModal.trigger')}
      </Link>
      <MinWidthModal
        open={isModalOpen}
        onClickOverlay={() => setIsModalOpen(false)}
        style={{ minWidth: '40%' }}
      >
        <UnderlineModalHeader>
          {i18n.t('appDetails.sideBar.historyModal.title')}
        </UnderlineModalHeader>
        <MinWidthModalBody>
          {versionHistory.map((version) => (
            <Flex key={version.semantic_version} direction="column" w>
              <H3>{version.semantic_version}</H3>
              <P>
                {version.changelog ||
                  i18n.t('appDetails.sideBar.historyModal.noChangelog')}
              </P>
            </Flex>
          ))}
        </MinWidthModalBody>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setIsModalOpen(false)}>
            {i18n.t('appDetails.sideBar.returnToListing')}
          </Button>
        </Modal.Footer>
      </MinWidthModal>
    </>
  );
};

import React from 'react';
import PT from 'prop-types';
import { useI18nContext } from '@procore/core-react';
import {
  ContactContainer,
  ContactsContainer,
  ContactType,
  DualSectionsContainer,
  HelpfulLinksContainer,
  LinksContainer,
  Logo,
  SectionContainer,
  StyledExternalLink,
  StyledH1,
  StyledH2,
  StyledLink,
  SupportCard,
} from './Support.styles';

const SupportSection = ({ name, website, contactUsEmail, helpfulLinks }) => {
  const i18n = useI18nContext();

  return (
    <SupportCard data-testid="support-section" id="support-section">
      <StyledH1>{i18n.t('appDetails.titles.support')}</StyledH1>
      <DualSectionsContainer>
        <SectionContainer>
          <Logo src="/images/helpful-links.svg" />
          <LinksContainer>
            <StyledH2>{i18n.t('appDetails.titles.helpfulLinks')}</StyledH2>
            <HelpfulLinksContainer>
              {helpfulLinks.map(({ url, label }) => (
                <StyledLink key={label} href={url} target="_blank" rel="noopener noreferrer">
                  {label}
                  <StyledExternalLink />
                </StyledLink>
              ))}
            </HelpfulLinksContainer>
          </LinksContainer>
        </SectionContainer>
        <SectionContainer>
          <Logo src="/images/contact.svg" />
          <ContactsContainer>
            <StyledH2>{i18n.t('appDetails.titles.contact')}</StyledH2>
            <ContactContainer>
              <ContactType>Email</ContactType>
              <StyledLink href={`mailto:${contactUsEmail}`}>
                {contactUsEmail}
              </StyledLink>
            </ContactContainer>
            <ContactContainer>
              <ContactType>{i18n.t('appDetails.labels.website')}</ContactType>
              <StyledLink href={website} target="_blank" rel="noopener noreferrer">
                {name}
                <StyledExternalLink />
              </StyledLink>
            </ContactContainer>
          </ContactsContainer>
        </SectionContainer>
      </DualSectionsContainer>
    </SupportCard>
  );
};

SupportSection.propTypes = {
  name: PT.string.isRequired,
  website: PT.string.isRequired,
  contactUsEmail: PT.string.isRequired,
  helpfulLinks: PT.arrayOf(
    PT.shape({
      url: PT.string.isRequired,
      label: PT.string.isRequired,
    })
  ),
};

export default SupportSection;

import React from 'react';
import {
  Flex,
  Popover,
  Typography,
  useI18nContext,
  Link,
} from '@procore/core-react';
import { ExternalLink, Info } from '@procore/core-icons';

const EMBEDDED_TYPES = ['iframe', 'fullscreen', 'sidepanel'];
const embeddedType = {
  fullscreen: 0,
  sidepanel: 1,
  both: 2,
};

const getEmbeddedType = (components) => {
  if (components.length === 0) {
    return embeddedType.fullscreen;
  }
  let fullscreen = false;
  let sidepanel = false;
  components.forEach((component) => {
    if (component === 'fullscreen') {
      fullscreen = true;
    }
    if (component === 'sidepanel') {
      sidepanel = true;
    }
  });

  if (fullscreen && sidepanel) {
    return embeddedType.both;
  }
  if (fullscreen) {
    return embeddedType.fullscreen;
  }
  return embeddedType.sidepanel;
};

const getType = (components) => {
  if (components.length === 0) {
    return 'dataConnector';
  }
  let dataConnector = false;
  let embedded = false;
  components.forEach((component) => {
    if (component === 'oauth') {
      dataConnector = true;
    }
    if (EMBEDDED_TYPES.includes(component)) {
      embedded = true;
    }
  });

  if (dataConnector && embedded) {
    return 'dataConnectorAndEmbedded';
  }
  if (dataConnector) {
    return 'dataConnector';
  }
  return 'embedded';
};

export const hasEmbedded = (components) => {
  return components.some((component) => EMBEDDED_TYPES.includes(component));
};

export const AppType = ({ components }) => {
  const i18n = useI18nContext();
  const overlay = (
    <Popover.Content>
      <Flex direction="column" gap="sm" padding="lg">
        <Typography>{i18n.t('appDetails.sideBar.appTypePopover')}</Typography>
        <Typography>
          <Flex alignItems="center" gap="sm">
            <Link
              href="https://developers.procore.com/documentation/building-apps-app-types"
              target="_blank"
            >
              {i18n.t('appDetails.sideBar.appTypeLink')}{' '}
              <ExternalLink size="sm" />
            </Link>
          </Flex>
        </Typography>
      </Flex>
    </Popover.Content>
  );
  return (
    <Flex direction="row" gap="sm" alignItems="center" justifyContent="center">
      <Typography>
        {i18n.t(`appDetails.sideBar.appType.${getType(components)}`)}
      </Typography>
      <Popover overlay={overlay} trigger="hover" key="example-2">
        {() => <Info size="sm" />}
      </Popover>
    </Flex>
  );
};

const EmbeddedTypePopover = ({ type }) => {
  const i18n = useI18nContext();
  let content = '';
  switch (type) {
    case embeddedType.fullscreen:
      content = i18n.t('appDetails.sideBar.embeddedTypePopover.fullscreen');
      break;
    case embeddedType.sidepanel:
      content = i18n.t('appDetails.sideBar.embeddedTypePopover.sidepanel');
      break;
    case embeddedType.both:
      content = i18n.t('appDetails.sideBar.embeddedTypePopover.both');
      break;
    default:
      content = 'missingContent';
  }
  return <Typography>{content}</Typography>;
};

export const EmbeddedType = ({ components }) => {
  const i18n = useI18nContext();
  const translatedComponents = components
    .filter((component) => EMBEDDED_TYPES.includes(component))
    .map((component) => i18n.t(`appDetails.sideBar.displayType.${component}`));
  const embeddedType = getEmbeddedType(components);
  const overlay = (
    <Popover.Content>
      <Flex direction="column" gap="sm" padding="lg">
        <EmbeddedTypePopover type={embeddedType} />
        <Typography>
          <Flex alignItems="center" gap="sm">
            <Link
              href="https://developers.procore.com/documentation/building-apps-app-types"
              target="_blank"
            >
              {i18n.t('appDetails.sideBar.embeddedType')}{' '}
              <ExternalLink size="sm" />
            </Link>
          </Flex>
        </Typography>
      </Flex>
    </Popover.Content>
  );
  return (
    <Flex direction="row" gap="sm" alignItems="center" justifyContent="center">
      <Typography>{translatedComponents.join(', ')}</Typography>
      <Popover overlay={overlay} trigger="hover" key="example-2">
        {() => <Info size="sm" />}
      </Popover>
    </Flex>
  );
};

import 'stylesheets/application.scss';
import '../react/shared/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import 'jquery-modal';
import 'slick-carousel';

import '@/scripts';

import {
  TopNavSearchForm,
  TopNavHeader,
  CompanyPicker,
  InstallRequestButton,
} from '@/react/components';
import Popover from '@/react/shared/Popover';
import {
  ListingsIndex,
  ListingsShow,
  MarketplaceIndex,
} from '@/react/views/V1';
import {
  MarketplaceListingContainer,
  MarketplaceAppContainer,
  FAQContainer,
} from '@/react/views/V2';

// Make React/Jquery Dependencies Global For Rails View Consumption
window.React = React;
window.ReactDOM = ReactDOM;
window.$ = $;
window.jQuery = $;

window.TopNavSearchForm = TopNavSearchForm;
window.TopNavHeader = TopNavHeader;
window.Popover = Popover;
window.CompanyPicker = CompanyPicker;
window.InstallRequestButton = InstallRequestButton;
window.ListingsIndex = ListingsIndex;
window.ListingsShow = ListingsShow;
window.MarketplaceListingContainer = MarketplaceListingContainer;
window.MarketplaceAppContainer = MarketplaceAppContainer;
window.Index = MarketplaceIndex;
window.FAQContainer = FAQContainer;

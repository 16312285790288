import styled, { css } from 'styled-components';
import {
  Box,
  Flex,
  Link,
  Modal,
  H1,
  Card,
  spacing,
  colors,
} from '@procore/core-react';

const SmallLogoWrapper = styled(Flex)`
  width: 75px;
  height: 75px;
  background: #ffffff;
  border: 1px solid #c2c2c2;
  padding: 10px;
`;

const MediumLogoWrapper = styled(Flex)`
  width: 100px;
  height: 100px;
  background: #ffffff;
  margin: 0px 38px 0px 0px;
  padding: 0px;
`;

const SmallLogo = styled.img`
  width: 100%;
  height: auto;
`;

const Container = styled(Box)`
  width: 100%;
  margin: 0 auto;
`;

const Slide = styled.img`
  object-fit: contain;
`;

const arrowMargin = 64;

const SlickWrapper = styled(Box)`
  .slick-arrow {
    &:before {
      color: #1d3243;
    }
  }

  margin: 0 ${arrowMargin}px;
  @media (min-width: 1024px) {
    width: 644px;
  }

  @media (max-width: 1023px) {
    width: 532px;
  }

  @media (max-width: 768px) {
    width: 272px;
  }
`;

// white circle with diameteter of 48px with containing elements centered inside
const arrows = css`
  background: white;
  width: 48px;
  height: 48px;

  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:before {
    content: none;
  }

  &:hover {
    background: #1d3243;
  }
`;

const ArrowContainer = styled(Box)`
  background: white;
  width: 48px;
  height: 48px;

  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: #1d3243;
  }
`;

const SlickArrowPrev = styled(Box)`
  transform: translate(-100%, -50%);
  &:before {
    content: none;
  }
  width: auto;
`;

const SlickArrowNext = styled(Box)`
  transform: translate(100%, -50%);
  &:before {
    content: none;
  }
  width: auto;
`;

const OrangeLink = styled(Link)`
  color: #f47e42;
`;

const ImageModal = styled(Modal)`
  background: transparent;
  box-shadow: none;
  overflow: visible;
  > div {
    overflow: visible;
  }
`;

const ModalImageBody = styled(Box)`
  width: 90vw;
  height: 90vh;
  max-width: none;
  justify-content: center;
  overflow: visible;
  position: relative;
  display: flex;

  > div > div {
    max-height: none;
  }
`;

const ModalImage = styled.img`
  object-fit: contain;
  box-shadow: 0px 0px 32px -4px hsl(200deg 10% 15% / 64%);
  max-width: 100%;
  max-height: 100%;
`;

const CloseImageModalButton = styled.button`
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background-color: #000;
  border: 2px solid #fff;

  &:after {
    content: '\\00d7';
    color: #fff;
  }
`;

const NameText = styled(H1)`
  font-size: 44px;
  margin: 8px auto;
`;

const UnstyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
`;

const DropdownMenu = styled(Card)`
  margin-top: ${spacing.sm}px;
  background-color: ${colors.white};
  border-radius: 8px;
  padding: ${spacing.sm}px;
  box-shadow: 0px 4px 28px 0px rgba(0, 0, 0, 0.28);
`;

const DropdownItem = styled(Box)`
  background-color: ${colors.white};
  border-radius: 8px;
  padding: ${spacing.md}px;
  margin: ${spacing.xs}px 0;
  width: 100%;

  &:hover {
    background-color: ${colors.gray94};
  }
`;

const Divider = styled.div`
  width: 100%;
  border-top: 1px solid ${colors.gray94};
  height: 2px;
  margin: ${spacing.sm}px 0;
`;

const LargeSpacingDivider = styled.div`
  width: 100%;
  border-top: 1px solid ${colors.gray94};
  height: 2px;
  margin: ${spacing.sm}px 0;
`;

const UnderlineModalHeader = styled(Modal.Header)`
  border-bottom: 1px solid ${colors.gray85};
`;

const MinWidthModal = styled(Modal)`
  width: 40%;
`;

const MinWidthModalBody = styled(Modal.Body)`
  width: 100%;
  max-width: 100%;
`;

const MediaContainer = styled(Flex)`
  max-width: 1200px;
  @media (max-width: 768px) {
    margin: 48px ${spacing.md}px;
  }

  @media (min-width: 769px) {
    margin: 48px 48px;
  }

  @media (min-width: 1200px) {
    margin: 48px 120px;
  }

  @media (min-width: 1920px) {
    margin: 48px 360px;
  }
`;

const SidebarContainer = styled(Flex)`
  justify-content: center;

  @media (max-width: 1400px) {
    justify-content: flex-start;
    margin-left: 100px;
  }
`;

export {
  Container,
  SmallLogo,
  SmallLogoWrapper,
  MediumLogoWrapper,
  NameText,
  SlickArrowNext,
  SlickArrowPrev,
  SlickWrapper,
  Slide,
  OrangeLink,
  ImageModal,
  ModalImageBody,
  ModalImage,
  CloseImageModalButton,
  UnstyledLink,
  DropdownMenu,
  DropdownItem,
  Divider,
  LargeSpacingDivider,
  ArrowContainer,
  UnderlineModalHeader,
  MinWidthModal,
  MinWidthModalBody,
  MediaContainer,
  SidebarContainer,
};

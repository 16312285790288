import React, { useEffect, useState } from 'react';
import {
  Box,
  useI18nContext,
  Link,
  Pill,
  colors,
  spacing,
  Flex,
  Typography,
  Button,
} from '@procore/core-react';
import { ExternalLink } from '@procore/core-icons';
import styled from 'styled-components';
import { useAppContext } from '@/react/context/AppContext';
import { RegionModal } from '../utils/RegionModal';
import { VersionHistoryModal } from '../utils/VersionHistoryModal';
import { AppType, EmbeddedType, hasEmbedded } from '../utils/AppType';

const CategoryPill = styled(Pill)`
  color: white;
  background-color: ${colors.blue50};
  font-weight: 400;
  font-size: 14px;
  padding: ${spacing.xxs}px ${spacing.md}px;
`;

const SectionLabel = styled(Typography)`
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${colors.gray60};
`;

const RowGapFlex = styled(Flex)`
  row-gap: ${spacing.sm}px;
`;

const SideBarSection = ({ title, children }) => (
  <Flex paddingBottom="xl" gap="sm" direction="column">
    <SectionLabel intent="label">{title}</SectionLabel>
    {children}
  </Flex>
);

const SideBar = () => {
  const i18n = useI18nContext();

  const {
    appDetails: { app, version },
  } = useAppContext();

  const {
    built_by,
    email,
    name,
    website,
    category_names,
    regions,
    countries,
    versioning_history,
    components,
  } = app;

  const latestVersion = versioning_history[0]?.semantic_version;

  const [hasEmbeddedComponents, sethasEmbeddedComponents] = useState(
    hasEmbedded(components)
  );
  useEffect(() => {
    sethasEmbeddedComponents(hasEmbedded(components));
  }, [components]);

  return (
    <div data-testid="app-sidebar">
      <Box marginTop="md">
        <SideBarSection title={i18n.t('appDetails.titles.appType')}>
          <AppType components={components} />
        </SideBarSection>
        {hasEmbeddedComponents && (
          <SideBarSection title={i18n.t('appDetails.titles.embeddedType')}>
            <EmbeddedType components={components} />
          </SideBarSection>
        )}
        <SideBarSection title={i18n.t('appDetails.titles.category')}>
          <RowGapFlex wrap="wrap" rowGap="sm">
            {category_names?.map((name) => (
              <Box marginRight="sm">
                <CategoryPill>{name}</CategoryPill>
              </Box>
            ))}
          </RowGapFlex>
        </SideBarSection>
        <SideBarSection title={i18n.t('appDetails.titles.availableIn')}>
          <Box gap="sm">
            {regions &&
              regions.length > 0 &&
              regions.map((region) => (
                <Typography intent="body">{`${region}${
                  countries[region] ? ` (${countries[region].length})` : ''
                }; `}</Typography>
              ))}
          </Box>
          <RegionModal countries={countries} regions={regions} />
        </SideBarSection>
        <SideBarSection title={i18n.t('appDetails.titles.version')}>
          <Typography intent="body">{version || latestVersion}</Typography>
          <VersionHistoryModal versionHistory={versioning_history} />
        </SideBarSection>
        <SideBarSection title={i18n.t('appDetails.titles.website')}>
          {/* styling bug here around no anchor underline */}
          <Link href={website} target="_blank">
            {built_by || name} <ExternalLink size="sm" />{' '}
          </Link>
        </SideBarSection>
      </Box>
    </div>
  );
};

export default SideBar;

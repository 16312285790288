import React from 'react';
import styled from 'styled-components';
import {
  Avatar,
  Box,
  colors,
  Grid,
  LinkButton,
  OverlayTrigger,
  spacing,
  Typography,
  useI18nContext,
} from '@procore/core-react';
import { Building, Import, Person } from '@procore/core-icons';
import { useAppContext } from '@/react/context/AppContext';
import { DropdownMenu, Divider, UnstyledLink } from '@/react/styles';

const StyledBox = styled(Box)`
  background-color: ${colors.gray94};
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.gray90};
  }
`;

const CompanyName = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
`;

const MenuItem = styled(Box)`
  border-radius: ${spacing.xs}px;
  text-align: center;

  &:hover {
    background-color: ${colors.gray94};
  }
`;

const ProfileBox = () => {
  const i18n = useI18nContext();
  const { header } = useAppContext();
  const {
    currentCompany,
    currentUser,
    isSignedIn,
    signInPath,
    signOutPath,
    changeCompanyPath,
  } = header;

  if (!isSignedIn) {
    return (
      <LinkButton href={signInPath} size="lg">
        {i18n.t('labels.login')}
      </LinkButton>
    );
  }

  const renderMenu = (
    <DropdownMenu>
      <Grid gutterX="sm" gutterY="sm">
        <Grid.Row>
          <Grid.Col colWidth={12}>
            <Box padding="xs md" display="flex" gap="xs" alignItems="center">
              <Typography intent="body" weight="semibold">
                {currentUser.email}
              </Typography>
            </Box>
            <Divider />
            <UnstyledLink href={changeCompanyPath} target="_blank">
              <MenuItem
                display="flex"
                gap="sm"
                alignItems="center"
                padding="xs md"
              >
                <Building />
                <Typography intent="label">
                  {i18n.t('navbar.companySwitch')}
                </Typography>
              </MenuItem>
            </UnstyledLink>
            <Divider />
            <UnstyledLink href={signOutPath} target="_self">
              <MenuItem
                display="flex"
                gap="sm"
                alignItems="center"
                padding="xs md"
              >
                <Import
                  color={colors.red50}
                  fill={colors.red50}
                  stroke={colors.red50}
                />
                <Typography intent="label" color="red50">
                  {i18n.t('navbar.logout')}
                </Typography>
              </MenuItem>
            </UnstyledLink>
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </DropdownMenu>
  );

  return (
    <OverlayTrigger placement="bottom-right" overlay={renderMenu}>
      <StyledBox display="flex" gap="sm" padding="xs" alignItems="center">
        <Avatar aria-label="My Account" size="lg" style={{ cursor: 'pointer' }}>
          <Avatar.Icon icon={<Person />} />
        </Avatar>
        <Box
          display="flex"
          style={{
            backgroundColor: colors.white,
            borderRadius: '10px',
            height: '100%',
          }}
          padding="sm"
        >
          <CompanyName
            intent="body"
            weight="semibold"
            aria-label={currentCompany.name}
          >
            {currentCompany.name}
          </CompanyName>
        </Box>
      </StyledBox>
    </OverlayTrigger>
  );
};

export default ProfileBox;

import React, { useMemo, createContext, useContext } from 'react';
import PT from 'prop-types';

const defaultProps = {
  appDetails: {
    app: {},
    user: {},
    installationRequest: {
      apiPath: '',
      present: false,
    },
    companyInstallationRequestsEnabled: false,
    companyName: '',
    version: '',
    metricsData: {},
  },
  header: {},
  listing: {
    apps: [],
    categories: [],
    query: '',
  },
};

const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

export const AppContextProvider = ({
  children,
  appDetails,
  listing,
  header,
}) => {
  const contextValue = useMemo(
    () => ({
      ...defaultProps, // Fill any missing values from defaultProps
      appDetails,
      header,
      listing,
    }),
    [appDetails, header, listing]
  );

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

AppContextProvider.propTypes = {
  children: PT.node.isRequired,
  appDetails: PT.shape({
    app: PT.shape({
      built_by: PT.string,
      email: PT.string,
      name: PT.string,
      html: PT.string,
      installed: PT.bool,
      pricing: PT.bool,
      create_lead: PT.bool,
      contact_us_email: PT.string,
      search_description: PT.string,
      small_logo: PT.shape({
        url: PT.string,
      }),
      small_logo_url: PT.string,
      website: PT.string,
      category_names: PT.arrayOf(PT.string),
      components: PT.arrayOf(PT.string),
      developer_app_id: PT.string,
      direct_installable: PT.bool,
      install_path: PT.string,
      price_button: PT.string,
      screenshots: PT.arrayOf(
        PT.shape({
          url: PT.string,
        })
      ),
      regions: PT.arrayOf(PT.string),
      countries: PT.shape({}),
      security_badge: PT.bool,
      security_questionnaire_document: PT.string,
    }),
    installationRequest: PT.shape({
      apiPath: PT.string,
      present: PT.bool,
    }).isRequired,
    companyName: PT.string.isRequired,
    user: PT.shape({
      email: PT.string,
      isCompanyAdmin: PT.bool.isRequired,
      isSignedIn: PT.bool.isRequired,
    }).isRequired,
    companyInstallationRequestsEnabled: PT.bool,
    metricsData: PT.shape({}),
    wistiaId: PT.string,
  }),
  header: PT.shape({}).isRequired,
  listing: PT.shape({
    apps: PT.shape([]),
    categories: PT.shape([]),
    query: PT.string,
  }),
};

import React from 'react';
import {
  Box,
  Typography,
  Flex,
  colors,
  useI18nContext,
  LinkButton,
} from '@procore/core-react';
import styled from 'styled-components';
import { Divider } from '@/react/styles';
import { NAVIGATION_MENU_ITEMS } from '@/react/consts';

const BodyContainer = styled(Box)`
  background-color: ${colors.gray96};
  flex-grow: 1;
`;

const MenuItem = styled(LinkButton)`
  align-items: center;
  justify-content: flex-start;
`;

const MenuBody = () => {
  const i18n = useI18nContext();
  return (
    <BodyContainer padding="lg xl">
      <Box display="block" paddingBottom="sm">
        <Typography intent="h3">{i18n.t('navbar.supportCenter')}</Typography>
      </Box>

      <Divider color={colors.gray90} />

      <Flex direction="column" gap="sm">
        {NAVIGATION_MENU_ITEMS.map((item) => (
          <MenuItem key={item.href} href={item.href} block variant="tertiary">
            <Typography intent="small" weight="semibold">
              {i18n.t(item.label)}
            </Typography>
          </MenuItem>
        ))}
      </Flex>
    </BodyContainer>
  );
};

export default MenuBody;

import React from 'react';
import PT from 'prop-types';
import { Box, Button, colors, Typography } from '@procore/core-react';
import { Clear } from '@procore/core-icons';
import styled from 'styled-components';
import { UnstyledLink } from '@/react/styles';
import MobileLogo from '../ProcoreLogo/MobileLogo';

const HeaderContainer = styled(Box)`
  background-color: ${colors.white};
`;

const MenuHeader = ({ toggleMenu, marketplaceBasePath, title }) => (
  <HeaderContainer
    display="flex"
    justifyContent="space-between"
    padding="lg xl"
  >
    <UnstyledLink href={marketplaceBasePath}>
      <Box display="inline-flex" gap="md" alignItems="center">
        <MobileLogo />
        <Typography intent="h1" weight="semibold">
          {title}
        </Typography>
      </Box>
    </UnstyledLink>
    <Button variant="tertiary" icon={<Clear />} onClick={toggleMenu} />
  </HeaderContainer>
);

MenuHeader.propTypes = {
  toggleMenu: PT.func.isRequired,
  marketplaceBasePath: PT.string.isRequired,
  title: PT.string.isRequired,
};

export default MenuHeader;
